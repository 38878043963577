import {  Fragment, useEffect, useState, useRef } from "react"
import { ProductModel } from "../../models/product.model"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../redux/rootReducer"
import { Product } from "../Product/Product"
import { ScrollToTop } from "./Common/ScrollToTop"
import { WebProductDetails } from "./Web/_WebProductDetails"
import './ProductList.scss'
import { Event, EventTypes, ProductUsageEvent } from "../../../../common/models/events/event.model";
import { EventsService } from "../../../../common/services/events.service"
import { setDisplayedProducts, setSelectedProduct } from "../../../../redux/product/productActions"
import { setSurfaces } from "../../../../redux/surfaces/surfacesActions"
import { VisualizeModel } from "../../models/visualize.model"
import { SpaceCatalogService } from "../../services/space-catalog.service"
import ScrollToBottom from "./Common/ScrollToBottom"
import ScrollToRight from "./Common/ScrollToRight"

export const ProductList = (props) => {

    const {isCatalogShownAsList} = useSelector((state: FullState) => state.generals)
    const {selectedWallsIds, isFloorSelected, isWallsSelected, isRugSelected, visualizeData } = useSelector((state: FullState) => state.surfaces)
    const {products, displayedProducts} = useSelector((state: FullState) => state.productsCatalog)
    const containerRef = useRef(null);
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const {isOnBookmarks} = useSelector((state: FullState) => state.generals)

    const [chunk, setChunk] = useState<any>()

    const dispatch = useDispatch()

    const vendorHasRugsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "interactive_floor")))
    const vendorHasFloorProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "floor")))
    const vendorHasWallsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "walls")))

    useEffect(() => {
        if(!isOnBookmarks){
            if(isWallsSelected && products && vendorHasWallsProducts()){
                dispatch(setDisplayedProducts(products.filter(e => e.application_types?.findIndex(e => e === "walls") > -1)))
            } else if(isFloorSelected && products && vendorHasFloorProducts()){
                dispatch(setDisplayedProducts(products.filter(e => e.application_types?.findIndex(e => e === "floor") > -1)))
            } else if(isRugSelected && products && vendorHasRugsProducts()){
                dispatch(products.filter(e => e.application_types?.findIndex(e => e === "interactive_floor") > -1))
            } 
        }
    },[isFloorSelected, isRugSelected, isWallsSelected, products])


    const handleProductSelect = async (product) => {
        const selectedSurface = isFloorSelected ? "floor" : isWallsSelected ? "wall" : isRugSelected ? "rug" : ""
        EventsService.push(new Event(EventTypes.ProductUsageEvent, new ProductUsageEvent(product, selectedSpace, selectedSurface)))

        if(!isRugSelected){
            dispatch(setSelectedProduct(product))
            let data = {...visualizeData}

            if(isWallsSelected)
                selectedWallsIds.forEach(wall => {
                    data = selectProductForSurface(data, wall, "walls", product, selectedSpace.id)
                })
            if(isFloorSelected){
                data = selectProductForSurface(data, 0, "floor", product, selectedSpace.id)
            }
            dispatch(setSurfaces(data, selectedSpace.id))

            runProcess(data)
        } else {
            dispatch(setSelectedProduct(product))
        }
    }

    const selectProductForSurface = (data: VisualizeModel, surfaceId, surfaceType, product: ProductModel, actualSpace: string) => {
        const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
        let surfaces = data[surfaceType]
        let orientation = 0
        if(existingSurface){
            surfaces = data[surfaceType].filter(surface => surface !== existingSurface)
            orientation = existingSurface.orientation
        }

        return {
            ...data,
            [surfaceType]: [...surfaces, {
                orientation: orientation,
                productId: product.id,
                surfaceKey: surfaceId
            }]
        }
    }

    const runProcess = (data) => {
        if(isFloorSelected || (isWallsSelected && selectedWallsIds.length > 0)){
            spaceCatalogService.processProduct(data, selectedSpace)
        }
        
    }

    const mediaCSS = () => {
        if(props.view === "web") 
            return {
                flexDirection: isCatalogShownAsList ? "column" : "row",
                flexWrap: isCatalogShownAsList ? "": "wrap",
                justifyContent: isCatalogShownAsList ? "" : "flex-start",
                alignContent: isCatalogShownAsList ? "" : "flex-start",
                gap: isCatalogShownAsList ? "" : "5px"

            }
        if(props.view === "mobile")
            return {
                overflowY: "hidden",
                paddingBottom: 1
            }
    }

    const updateDisplayedProducts = (selectedProduct, sibling) => {
        if(selectedProduct.id !== sibling.id){
            const selectedProductIndex = displayedProducts.findIndex(product => product.id === selectedProduct.id)
            const siblings = selectedProduct.siblings.map(e => {
                if(e.id === sibling.id){
                    return {
                        _id: sibling._id,
                        ...selectedProduct,
                        isDefault: false
                    }
                }
                else return e
            })
            let newMainProduct = {...sibling}
            newMainProduct.siblings = siblings
            newMainProduct.isDefault = true
            const tempProducts = [...displayedProducts]
            tempProducts[selectedProductIndex] = newMainProduct
            dispatch(setDisplayedProducts(tempProducts))
        }
    }

    const handleSiblingSelected = async (oldProduct, product) => {
        updateDisplayedProducts(oldProduct, product)
        handleProductSelect(product)
    }

    useEffect(() => {
        setChunk(chunkArray(displayedProducts.filter(product => product.isDefault), 3))
    },[displayedProducts])

    return (
        <>
            <Box
                ref={containerRef}
                className={props.view === "web" ? "product-list-web": "product-list-mobile"}
                sx={mediaCSS()}>
                {isCatalogShownAsList && displayedProducts.filter(product => product.isDefault)?.map((item: ProductModel) => (
                    <Product
                        {...props}
                        key={item.id}
                        item={item}
                        updateDisplayedProducts={updateDisplayedProducts}
                        handleProductSelect={handleProductSelect}
                        handleSiblingSelected={handleSiblingSelected}
                        isShownAsList={isCatalogShownAsList} 
                        showActions={true}
                        />

                ))}
                {!isCatalogShownAsList && (
                    <Fragment>
                        {chunk && chunk.map((chunk, index) => (
                            <Box key={index} display="flex" justifyContent="flex-start" flexWrap="wrap" gap={"5px"}>
                                {chunk.map((item: ProductModel) => (
                                    <Product
                                        {...props}
                                        key={item.id}
                                        item={item}
                                        isShownAsList={isCatalogShownAsList}
                                        handleProductSelect={handleProductSelect}
                                        handleSiblingSelected={handleSiblingSelected}
                                        showActions={true}
                                    />
                                ))}
                                <WebProductDetails chunk={chunk} handleSiblingSelected={handleSiblingSelected}/>
                            </Box>
                        ))}
                    </Fragment>
                )}
            </Box>
            <ScrollToTop containerRef={containerRef} />
            <ScrollToBottom containerRef={containerRef}/>
            {props.view === "mobile" && <ScrollToRight containerRef={containerRef} />}
        </>
    )
}

function chunkArray(array: any[], size: number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
}